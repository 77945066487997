<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Blogs/Edit</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <form action="">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-8">
                  <div class="col-12">
                    <div class="form-group">
                      <div>
                        <label for="title">Title *</label>
                        <input v-model="putBlogData.title" type="text" class="form-control" id="title"
                               placeholder="Enter Blog Title">
                      </div>
                      <div v-if="errors.blog.title" class="text-danger">{{ errors.blog.title }}</div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group">
                      <label>Description *</label>
                      <QuillEditor ref="descriptionRef" v-model:content="putBlogData.description" contentType="html" toolbar="full"
                                   theme="snow"/>
                      <div class="text-danger" v-if="errors.blog.description">{{ errors.blog.description }}</div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-4">
                  <div class="col-12">
                    <div class="form-group">
                      <div class="controls">
                        <label>Blog Category *</label>
                        <VueMultiselect :allowEmpty="false" v-model="selectedBlogCategory" class="multiselect-blue"
                                        :options="blogCategoryOptions"
                                        :close-on-select="true" placeholder="Select Blog Category" label="name"
                                        track-by="name"
                                        :show-labels="false"/>
                      </div>
                      <div class="text-danger" v-if="errors.blogCategoryId">{{ errors.blogCategoryId }}</div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group">
                      <div class="controls select-tags-wrapper">
                        <label>Tags (optional)</label>
                        <VueMultiselect :taggable="true"
                                        v-model="selectedBlogTags" class="multiselect-blue"
                                        :options="blogTagOptions"
                                        :close-on-select="true" :preselect-first="true" :clear-on-select="true"
                                        :multiple="true" placeholder="Select Blog Tags" label="name"
                                        track-by="name"
                                        :show-labels="false"
                                        @tag="createNewBlogTag"/>
                      </div>
                      <div class="text-danger" v-if="errors.blogTagId">{{ errors.blogTagId }}</div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group">
                      <div class="controls">
                        <label>Is Featured?</label>
                        <div style="margin-top: .5rem">
                          <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                            <div>
                              <input v-model="selectedIsFeatured" type="checkbox" class="custom-control-input" checked
                                     id="blogIsFeatured">
                              <label class="custom-control-label mr-1" for="blogIsFeatured"></label>
                            </div>
                            <span class="font-medium-1">{{ selectedIsFeatured ? "Yes" : "No" }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="text-danger" v-if="errors.blog.isFeatured">{{ errors.blog.isFeatured }}</div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group">
                      <div class="controls">
                        <label>Status</label>
                        <div style="margin-top: .5rem">
                          <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                            <div>
                              <input v-model="selectedStatus" type="checkbox" class="custom-control-input" checked
                                     id="blogStatus">
                              <label class="custom-control-label mr-1" for="blogStatus"></label>
                            </div>
                            <span class="font-medium-1">{{ selectedStatus ? "Active" : "Inactive" }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="text-danger" v-if="errors.blog.status">{{ errors.blog.status }}</div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <div class="d-flex justify-content-end">
                    <button  @click="updateSingleBlog" type="button"
                            class="btn btn-primary update-todo px-4">Update Blog
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
// components
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

// core packages
import {mapActions, mapGetters} from 'vuex';

// 3rd party packages
import VueMultiselect from 'vue-multiselect';
import {QuillEditor} from '@vueup/vue-quill';

// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

// css
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  components: {
    AppLayout,
    VueMultiselect,
    QuillEditor,
  },
  name: "BlogEdit.vue",
  mixins: [ShowToastMessage, Loader, Authorization],

  data() {
    return {
      selectedBlogTags: [],

      getBlogParamObj: {
        with_relation:['blogCategory', 'blogTags']
      },

      getBlogCategoriesParams: {
        status: 1,
      },

      existingBlogData: {
        blog_category_id: '',
        title: '',
        description: '',
        is_featured: '',
        status: '',
      },

      putBlogData: {
        blog_category_id: '',
        title: '',
        description: '',
        is_featured: '',
        status: '',
      },

      postBlogBlogTagStoreListData: {
        blog_id: null,
        blog_tag_id: null,
      },

      selectedIsFeatured: true,
      selectedStatus: true,

      errors: {
        blog: {
          blogCategoryId: '',
          blogTagId: '',
          title: '',
          description: '',
          isFeatured: '',
          status: ''
        }
      },

      selectedBlogCategory: {
        value: '',
        name: 'None'
      },

    }
  },
watch: {
   
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      blog: 'appBlogs/blog'
    }),
    redirectRouteName() {
      return this.can('blog-view-any') ? 'appBlogList' : 'appBlogEdit';
    },

    blogCategoryOptions() {
      let blogCategories = this.$store.getters['appBlogCategories/blogCategories'].map((blogCategory) => {
        let id = blogCategory.id;
        let name = blogCategory.name;
        return {value: id, name: name};
      });
      return [{value: '', name: 'None'}, ...blogCategories];
    },

    blogTagOptions() {
      return this.$store.getters['appBlogTags/blogTags'].map(blogTag => {
        return {
          value: blogTag.id,
          name: blogTag.name,
        };
      });
    },

    blogDataChange() {
      return (this.putBlogData.title !== this.existingBlogData.title) || (this.putBlogData.description !== this.existingBlogData.description) ||
          (this.putBlogData.blog_category_id !== this.existingBlogData.blog_category_id )|| ((this.selectedStatus ? 'Active' :'Inactive') !== this.existingBlogData.status) ||
          ((this.selectedIsFeatured ? 'Yes' : 'No') !== this.existingBlogData.is_featured);
    }
  },

  methods: {
    ...mapActions({
      getBlog: 'appBlogs/getBlog',
      putBlog: 'appBlogs/putBlog',
      getBlogCategories: 'appBlogCategories/getBlogCategories',

      getBlogTags: 'appBlogTags/getBlogTags',
      postBlogBlogTagStoreList: 'appBlogBlogTags/postBlogBlogTagStoreList',
      putBlogTag: 'appBlogTags/putBlogTag',
    }),

    async getBlogCategoryList() {
      await this.getBlogCategories(this.getBlogCategoriesParams);
    },

    async getBlogTagList() {
      await this.getBlogTags();
    },

    async updateSingleBlog() {
      this.loader(true);
      let dataObj = {
        id: this.$route.params.id,
        data: {
          ...this.putBlogData,
          is_featured:this.selectedIsFeatured ? 1 : 0,
          status:this.selectedStatus ? 1 : 0,
          blog_category_id:this.selectedBlogCategory.value
        },

      };
    

      if (this.blogDataChange) {
        await this.putBlog(dataObj).then(async (response) => {
          this.loader(false);
          if (response.status === 200 || response.status === 201) {
            const toastObj = {
              message: 'Blog update successful.',
              type: 'success'
            };

            this.showToastMessage(toastObj);
          }

          this.errors.blogCategoryId = response.errors && response.errors.blog_category_id ? response.errors.blog_category_id[0] : '';
          this.errors.blogTagId = response.errors && response.errors.blog_tag_id ? response.errors.blog_tag_id[0] : '';
          this.errors.blog.title = response.errors && response.errors.title ? response.errors.title[0] : '';
          this.errors.blog.description = response.errors && response.errors.description ? response.errors.description[0] : '';
          this.errors.blog.isFeatured = response.errors && response.errors.isFeatured ? response.errors.isFeatured[0] : '';
          this.errors.blog.status = response.errors && response.errors.status ? response.errors.status[0] : '';

          if (response.message) {
            this.showToastMessage(response);
          }

        })
      }

      if(this.selectedBlogTags.length) {
        this.postBlogBlogTagStoreListData.blog_id = this.blog.id;
        this.postBlogBlogTagStoreListData.blog_tag_id = this.selectedBlogTags.map(blogTag => {
          return blogTag.value;
        });
        await this.createBlogBlogTagsStoreList();
      }

      await this.$router.push({
        name: this.redirectRouteName,
      });
    },

    async createBlogBlogTagsStoreList() {
      this.postBlogBlogTagStoreList(this.postBlogBlogTagStoreListData).then(async response => {
        if (response.message) {
          this.showToastMessage(response);
        }
      })
    },

    async createNewBlogTag(createNewBlogTag) {

      let data = {
        name: createNewBlogTag,
      }

      await this.postBlogTag(data);

      let blogTag = this.$store.getters['appBlogTags/blogTag'];
      let blogTagObj = { value: blogTag.id, name: blogTag.name };

      await this.getBlogTagList();

      this.selectedBlogTags.push(blogTagObj);
    },

    async getSingleBlog() {
      const paramObj = {
        id: this.$route.params.id,
        params: this.getBlogParamObj,
      };
      await this.getBlog(paramObj).then(async (response) => {
        if (response.status === 404) {
          this.commitNotFoundRouteStatus(true);
          return;
        }

        if (response.status === 200) {
          this.putBlogData.blog_category_id = this.blog.blogCategory.id;
          this.putBlogData.title = this.blog.title;
          this.putBlogData.description = this.blog.description;
          this.putBlogData.is_featured = this.blog.is_featured;
          this.putBlogData.status = this.blog.status;
          this.$refs.descriptionRef.setHTML(this.putBlogData.description);
          this.selectedStatus = this.putBlogData.status === "Active";
          this.selectedIsFeatured = this.putBlogData.is_featured === 'Yes';

          this.existingBlogData.blog_category_id = this.putBlogData.blog_category_id;
          this.existingBlogData.title = this.putBlogData.title;
          this.existingBlogData.description = this.putBlogData.description;
          this.existingBlogData.is_featured = this.putBlogData.is_featured;
          this.existingBlogData.status = this.putBlogData.status;

          this.selectedBlogCategory = this.blogCategoryOptions.find((item) => item.value === this.putBlogData.blog_category_id );

          this.selectedBlogTags = this.blog.blogTags.map(blogTag => {
            return this.blogTagOptions.find((item) => item.value === blogTag.id);
          });

        }

        this.showToastMessage(response);
      });
    },


  },


  async mounted() {
    this.loader(true);
    await this.getBlogCategoryList();
    await this.getBlogTagList();
    await this.getSingleBlog();
    this.loader(false);
  }

}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>

</style>